// Caution! The order matters!
export enum FoodFlavorEnum {
  Beef = 'beef',
  Fish = 'fish',
  Lamb = 'lamb',
  Turkey = 'turkey',
  Duck = 'duck'
}

export const FLAVOURS_SCORE_TABLE = {
  [FoodFlavorEnum.Beef]: 4,
  [FoodFlavorEnum.Duck]: 0,
  [FoodFlavorEnum.Fish]: 1,
  [FoodFlavorEnum.Lamb]: 2,
  [FoodFlavorEnum.Turkey]: 3
} as const

export const flavorsDictionary = {
  [FoodFlavorEnum.Beef]: 'wołowina',
  [FoodFlavorEnum.Duck]: 'kaczka',
  [FoodFlavorEnum.Fish]: 'ryba',
  [FoodFlavorEnum.Lamb]: 'jagnięcina',
  [FoodFlavorEnum.Turkey]: 'indyk'
}

export const reverseFlavorsDictionary = {
  wołowina: FoodFlavorEnum.Beef,
  kaczka: FoodFlavorEnum.Duck,
  ryba: FoodFlavorEnum.Fish,
  jagnięcina: FoodFlavorEnum.Lamb,
  indyk: FoodFlavorEnum.Turkey
}

export const translateFlavor = (flavor: FoodFlavorEnum) => flavorsDictionary[flavor]

export const INITIAL_PROPOSAL_FLAVORS = [
  FoodFlavorEnum.Turkey,
  FoodFlavorEnum.Beef,
  FoodFlavorEnum.Fish,
  FoodFlavorEnum.Lamb
]

export const PROPOSAL_HIDDEN_FLAVORS: FoodFlavorEnum[] = [
  // Example item:
  // FoodFlavorEnum.Lamb
]
